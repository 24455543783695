import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import logo from "../../../assets/logo.png";
import { useSelector, useDispatch } from "react-redux";
import { userRole, userAccount } from "../../../store/auth/selector";
import { RootState } from "../../../store/config/types";
import { Menu, MenuItem, Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { logout } from "../../../store/auth/actionCreators";
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import request from 'request';
import {BASE_URL_FOODS} from '../../../services/config';
import { PaymentStatus } from '../../../store/config/enums';

const ConsultaNutricional: React.FC = () => {
  const dispatch = useDispatch();
  const role = useSelector((state: RootState) => userRole(state));
  const actualUser = useSelector((state: RootState) => userAccount(state));
  const isFinalUser = role === 'user';
  const isNutritionist = role === 'nutricionist';
  const firstName = actualUser && actualUser.profile.firstName;
  const hasAnalysis = actualUser && actualUser.profile.analysis;
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
  });
  
  const [consultaSeleccionada, setConsultaSeleccionada] = useState('');
  const [preferenceId, setPreferenceId] = useState('');

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentStatus = queryParams.get('status');
  const [statusMP, setStatusMP] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [cartOpen, setCartOpen] = useState(false);

  const [preference, setPreference] = useState('');

  useEffect(() => {
    //ask if actual user exists
    if (!actualUser) {
      history.push('/');
    } else {
        setFormData({
          name: actualUser?.profile?.firstName || '',
          email: actualUser?.email || '',
          phone: actualUser?.profile?.mobile || '',
        }); 
        setConsultaSeleccionada('consultaPuntual');
    }

  }, []);

  useEffect(() => {
    console.log("payment-status")
    console.log(paymentStatus);
    if (paymentStatus !== "" && paymentStatus !== null) {
      if(queryParams.get('payment_id') === 'null') return;
      setStatusMP(paymentStatus);
      setPaymentId(queryParams.get('payment_id'));
    }
  }, [paymentStatus]);

  useEffect(() => {
      //testing
      // initMercadoPago('TEST-a10ff3b3-217c-461a-80bb-3f803033a060');
      //production
      initMercadoPago('APP_USR-097c1049-0ad3-4548-b85e-ba982fe5f695');
  }, [preference]);

  useEffect(() => {
    
    //cargo mercadopago
    // creo la preferencia de MercadoPago cuando cambia la consulta seleccionada
    console.log(formData);
    if (consultaSeleccionada) {
        const orderData = {
            tipoConsulta: consultaSeleccionada,
            user: {
              name: formData.name,
              email: formData.email,
              phone: formData.phone,
            },
        };
        const mrgenToken = {
          token: localStorage.getItem('token')
        };
        // Guardar la orden en LocalStorage
        //localStorage.setItem('orderData', JSON.stringify(orderData));
        
        //const orderFormatted = generateOrderSummaryAndObject(orderData);
        //const orderSummary = orderFormatted.summary;
        //const orderObject = addDatesToWeeksData(orderFormatted.orderObject);
        /* console.log(orderSummary); 
        console.log(orderObject);
        */
      request.post(
        `${BASE_URL_FOODS}/v1/payment/create_preference_hire_nutritionist`,
          {
            json: { orderData, mrgenToken },
          },
          (error, response, body) => {
            if (!error && response.statusCode === 200) {
              console.log('Pedido enviado exitosamente:', body);
              setPreference(body.id);
              //setFormSubmitted(true);
            } else {
              console.error('Error al enviar el pedido:', error || body);
              alert('Ocurrió un error al enviar el pedido. Por favor intenta nuevamente.');
            }
          }
      );
      /* fetch('/api/create_preference', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ consulta: consultaSeleccionada }),
      })
      .then(response => response.json())
      .then(data => setPreferenceId(data.preferenceId))
      .catch(error => console.error('Error:', error)); */
      console.log("consulta seleccionada", consultaSeleccionada); 
    }
  }, [consultaSeleccionada]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const selectOption = (option: number) => {
    if (option === 0) history.push("/profile");
    if (option === 1) {
      dispatch(logout());
      history.push("/");
    }
    if (option === 2) {
      history.push("/platos-semanales");
    }
    if(option === 3) {
      history.push("/consulta-nutricional");
    }
    setAnchorEl(null);
  };

  const formatDateToDayMonth = (date: string) => {
    return `${date.split("-")[2]}/${date.split("-")[1]}`;
  };

  const handleConsultaSelection = (consulta) => {
    if(consulta == consultaSeleccionada) return;
    setPreference('');
    setConsultaSeleccionada(consulta);
  };

  const handleCheckout = () => { 

    console.log("checkout");
  };

  const handleNavigation = () => {
    if (!window.localStorage.token) {
      history.push('/');
    } else if (!hasAnalysis) {
      history.push('/loadingPayment');
    } else {
      history.push('/dashboard');
    }
  };

  return (
    <div className="menu-semanal">
        <div className="logo-week-cart-header">
            <div className="meals-logo-header">
                <img
                    style={{ width: '160px' }}
                    src={logo}
                    alt="logo"
                    className="logo"
                    onClick={() => handleNavigation()}
                    />
                <div className="cart-icon-container">
                    {actualUser && (
                    <div className="profile-div" onClick={handleClick}>
                        <div className="image">{firstName ? firstName[0] : ''}</div>
                        <span>{firstName}</span>
                    </div>
                    )}
                </div>
                {actualUser && (
                        <Menu
                        keepMounted
                        anchorEl={anchorEl}
                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                        transformOrigin={{ horizontal: "left", vertical: "top" }}
                        getContentAnchorEl={null}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        >
                        <MenuItem onClick={() => selectOption(0)}>Editar perfil</MenuItem>
                        <MenuItem onClick={() => selectOption(2)}>Platos semanales</MenuItem>
                        <MenuItem onClick={() => selectOption(3)}>Consulta nutricional</MenuItem>
                        <MenuItem onClick={() => selectOption(1)}>Cerrar sesión</MenuItem>
                        </Menu>
                )}
            </div>
        
            <div className="week-cart consulta-cart">
                <div className="btn-consulta-container">
                    <Button className="btn-consulta" variant="contained" color="primary" size="large" onClick={() => handleConsultaSelection('consultaPuntual')}>
                        Consulta Inicial
                    </Button>
                    <Button className="btn-consulta" variant="contained" color="primary" size="large" onClick={() => handleConsultaSelection('consultaPlus4')}>
                        Consulta + Plan + 2 Controles
                    </Button>
                    <Button className="btn-consulta" variant="contained" color="primary" size="large" onClick={() => handleConsultaSelection('consultaPlus8')}>
                        Consulta + Plan + 6 Controles
                    </Button>
                    <Button className="btn-consulta" variant="contained" color="primary" size="large" onClick={() => handleConsultaSelection('controlesx2')}>
                        2 Controles de seguimiento
                    </Button>
                </div>
            </div> 
        </div>
        {
        statusMP && 
          (
          <div className="container-consulta-content">
            <h1 className="ccc-title">Pago completado</h1>
            <br/>
            <br/>
            <span><b>¡Gracias por tu compra! A la brevedad recibirás un email para agendar tu consulta</b></span>
            <br/>
            <span><b>Estado:</b> {statusMP === 'approved' ? "Aprobado" : statusMP}</span>
            <span><b>Id de pago:</b> {paymentId}</span>
          </div>)
        }
       {
          consultaSeleccionada == 'consultaPuntual' && !statusMP ? (
          <div className="container-consulta-content">
              <h1 className="ccc-title">Análisis Nutricional del Test Nutrigenético</h1>
              <div className="analysis-plus-container">
                
              <div className="image-container">
                <img src="https://res.cloudinary.com/hqs-cdn/image/upload/v1634906806/mistergen/landing-nutritionists-image_ltu9y3.png" alt="Consulta nutricional" />
              </div>
              
              <div className="content-container">
                
                <p>
                En esta sesión inicial, te ayudaremos a interpretar los resultados del informe de tu test nutrigenético para optimizar tus hábitos alimenticios.
                </p>
                <p><b>Incluye:</b></p>
                <ul>
                    <li><b className="pink-color">- Lectura y análisis del test nutrigenético.</b></li>
                    <li>- Consulta virtual de 30 minutos.</li>
                    <li>- Recomendaciones en función de los resultados.</li>
                </ul>
                
                <div className="price-container">
                  <p className="price-total">Precio total</p>
                  <p className="price-amount">$U 2.000 <span className="tax-included">Impuestos incluidos</span></p>
                </div>
                <div className="cho-container" />
                <div id="wallet_container"></div>
                {
                  preference && (
                    <Wallet initialization={{ 
                      preferenceId: preference }} 
                      customization={{ 
                        texts: { 
                        action: 'pay', 
                        actionComplement: 'amount',
                        valueProp: 'smart_option' 
                      } 
                    }} />
                  )
                }
                {/* <Button variant="outlined" style={{fontSize:'16px !important', margin:'0px 40px 0px 40px !important', width:'100%'}} color="primary" size="large" onClick={handleCheckout} className="checkout-button">Pagar con MercadoPago</Button> */}
              </div>
            </div>
              {/* <div id="mercadoPagoButton"></div> */}
          </div>
      ): consultaSeleccionada == 'consultaPlus4' && !statusMP ? (
        <div className="container-consulta-content">
              <h1 className="ccc-title">Análisis Nutricional del Test Nutrigenético + Plan Nutricional Personalizado + 2 Controles</h1>
              <div className="analysis-plus-container">
                
              <div className="image-container">
                <img src="https://res.cloudinary.com/hqs-cdn/image/upload/v1634906806/mistergen/landing-nutritionists-image_ltu9y3.png" alt="Consulta nutricional" />
              </div>
              
              <div className="content-container">
                
                <p>
                
                </p>
                <p><strong>Incluye 4 consultas:</strong></p>
                <ul>
                    <li><b className="pink-color">- Lectura y análisis del test nutrigenético.</b></li>
                    <li>- Consulta nutricional virtual de 30 minutos.</li>
                    
                    <li><b className="pink-color">- Entrevista personal, registro de datos para el armado del plan nutricional.</b></li>
                    <li>- Consulta virtual o presencial de 45 minutos.</li>
                    
                    <li><b className="pink-color">- Se realizarán 2 controles adicionales de revisión de objetivos y ajustes del plan nutricional.</b></li>
                    <li>- Consulta virtual o presencial de 30 minutos.</li>


                </ul>
                
                <div className="price-container">
                  <p className="price-total">Precio total</p>
                  <p className="price-amount">$U 7.590 <span className="tax-included">Impuestos incluidos</span></p>
                  <p className="installments">o hasta 6 cuotas de $U 1.265</p>              
                </div>
                <div className="cho-container" />
                <div id="wallet_container"></div>
                {
                  preference && (
                    <Wallet initialization={{ preferenceId: preference }} 
                      customization={{
                        texts: { 
                        action: 'pay', 
                        actionComplement: 'amount',
                        valueProp: 'smart_option' 
                      }
                    }} />
                  )
                }
                {/* <Button variant="outlined" style={{fontSize:'16px !important', margin:'0px 40px 0px 40px !important', width:'100%'}} color="primary" size="large" onClick={handleCheckout} className="checkout-button">Pagar con MercadoPago</Button> */}
              </div>
            </div>
              <div id="mercadoPagoButton"></div>
          </div>
      ) : consultaSeleccionada == 'consultaPlus8' && !statusMP ? (
        <div className="container-consulta-content">
              <h1 className="ccc-title">Análisis Nutricional del Test Nutrigenético + Plan Nutricional  Personalizado + 6 controles </h1>
              <div className="analysis-plus-container">
                
              <div className="image-container">
                <img src="https://res.cloudinary.com/hqs-cdn/image/upload/v1634906806/mistergen/landing-nutritionists-image_ltu9y3.png" alt="Consulta nutricional" />
              </div>
              
              <div className="content-container">
                
              <p></p>
              <p><strong>Incluye 8 consultas:</strong></p>
              <ul>
                <li><b className="pink-color">- Lectura y análisis del test nutrigenético.</b></li>
                <li>- Consulta nutricional virtual de 30 minutos.</li>
                
                <li><b className="pink-color">- Entrevista personal, registro de datos para el armado del plan nutricional.</b></li>
                <li>- Consulta virtual o presencial de 45 minutos.</li>
                
                <li><b className="pink-color">- Se realizarán 6 controles adicionales de revisión de objetivos y ajustes del plan nutricional.</b></li>
                <li>- Consulta virtual o presencial de 30 minutos.</li>

              </ul>
              
              <div className="price-container">
                <p className="price-total">Precio total</p>
                <p className="price-amount">$U 12.900 <span className="tax-included">Impuestos incluidos</span></p>
                <p className="installments">o hasta 6 cuotas de $U 2.150</p>
                
              </div>
              
                <div className="cho-container" />
                <div id="wallet_container"></div>
                {
                  preference && (
                    <Wallet initialization={{ preferenceId: preference }} 
                      customization={{
                        texts: { 
                        action: 'pay', 
                        actionComplement: 'amount',
                        valueProp: 'smart_option' 
                      }
                    }} />
                  )
                }
                {/* <Button variant="outlined" style={{fontSize:'16px !important', margin:'0px 40px 0px 40px !important', width:'100%'}} color="primary" size="large" onClick={handleCheckout} className="checkout-button">Pagar con MercadoPago</Button> */}
              </div>
            </div>
              <div id="mercadoPagoButton"></div>
          </div>
      ) : consultaSeleccionada == 'controlesx2' && !statusMP ? (
        <div className="container-consulta-content">
            <h1 className="ccc-title">2 controles de seguimiento nutricional</h1>
            <div className="analysis-plus-container">
              
            <div className="image-container">
              <img src="https://res.cloudinary.com/hqs-cdn/image/upload/v1634906806/mistergen/landing-nutritionists-image_ltu9y3.png" alt="Consulta nutricional" />
            </div>
            
            <div className="content-container">
              <p>
              </p>
              <p><b>Incluye:</b></p>
              <ul>
                  <li><b className="pink-color">- Se realizarán 2 controles de revisión de objetivos y ajustes del plan nutricional.</b></li>
                  <li>- Consulta virtual o presencial de 30 minutos.</li>
                  <li><b className="pink-color">- Válido para clientes que hayan contratado previamente consulta inicial y plan nutricional.</b></li>
              </ul>
              <div className="price-container">
                <p className="price-total">Precio total</p>
                <p className="price-amount">$U 2.860 <span className="tax-included">Impuestos incluidos</span></p>
              </div>
              <div className="cho-container" />
              <div id="wallet_container"></div>
              {
                preference && (
                  <Wallet initialization={{ 
                    preferenceId: preference }} 
                    customization={{ 
                      texts: { 
                      action: 'pay', 
                      actionComplement: 'amount',
                      valueProp: 'smart_option' 
                    } 
                  }} />
                )
              }
              {/* <Button variant="outlined" style={{fontSize:'16px !important', margin:'0px 40px 0px 40px !important', width:'100%'}} color="primary" size="large" onClick={handleCheckout} className="checkout-button">Pagar con MercadoPago</Button> */}
            </div>
          </div>
            {/* <div id="mercadoPagoButton"></div> */}
        </div>
    ): null
    }
        

    </div>
  );
};

export default ConsultaNutricional;


